<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="card card-custom gutter-b">
          <div class="card-header border-0 py-0">
            <h3 class="card-title align-items-center">
              <span class="card-label font-weight-bolder text-dark"
                >Transactions</span
              >
            </h3>
            <div class="card-toolbar">
              <button @click="refresh" class="btn btn-light-primary mr-2">
                <span class="svg-icon svg-icon-md mr-0">
                  <inline-svg src="media/svg/icons/Media/Repeat.svg" />
                </span>
              </button>
              <button
                @click.prevent="changeTableView(tableView)"
                class="btn btn-light-primary font-weight-bold px-5 mr-3"
              >
                {{ tableViewButtonText }}
              </button>
              <!-- <button
                @click.prevent="exportAsExcel"
                class="btn btn-light-primary font-weight-bold px-10"
              >
                Export .xlsx
              </button> -->
            </div>
          </div>
          <div class="card-body py-0">
            <div class="row mb-5">
              <div class="col-md-4">
                <div class="form-group mb-0">
                  <input
                    v-model="imei"
                    type="text"
                    class="form-control"
                    placeholder="IMEI"
                  />
                </div>
              </div>
              <div class="col-md-8">
                <DatePicker
                  v-model="range"
                  mode="dateTime"
                  :masks="masks"
                  locale="hr"
                  is-range
                  is24hr
                  :columns="$screens({ default: 1, lg: 1 })"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <div class="d-flex">
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text">
                            <i class="la la-calendar"></i>
                          </span>
                        </div>
                        <input
                          class="form-control"
                          :value="inputValue.start"
                          v-on="inputEvents.start"
                          :placeholder="fromText"
                        />
                      </div>
                      <div class="flex-shrink-0 my-3 mx-3">
                        <i class="la la-arrow-right"></i>
                      </div>
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text">
                            <i class="la la-calendar"></i>
                          </span>
                        </div>
                        <input
                          class="form-control"
                          :value="inputValue.end"
                          v-on="inputEvents.end"
                          :placeholder="toText"
                        />
                      </div>
                    </div>
                  </template>
                </DatePicker>
              </div>
            </div>
            <div class="row mb-5">
              <div class="col-md-12">
                <button
                  class="btn btn-block btn-light-primary font-weight-bold"
                  @click="filter"
                >
                  {{ $t('GENERAL.SEARCH') }}
                </button>
              </div>
            </div>
            <div class="separator separator-dashed mt-0 mb-5"></div>
            <div v-if="isLoading" class="col-12 text-center my-5 p-2 pt-5 bg-secondary">
              <div class="text-center my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong class="ml-4">{{ $t('GENERAL.LOADING') }}</strong>
              </div>
            </div>
            <div v-else-if="!isLoading && transactions.length > 0" class="table-responsive">
              <table
                class="table table-head-custom table-vertical-center table-head-bg"
              >
                <thead>
                  <tr class="text-uppercase">
                    <th class="pl-6">
                      <span>{{ $t('GENERAL.DEVICE') }}</span>
                    </th>
                    <th v-if="tableView === 'advanced'">
                      <span>Isu Number</span>
                    </th>
                    <th v-if="tableView === 'advanced'">
                      <span>Business Space Label</span>
                    </th>
                    <th>
                      <span>{{ $t('GENERAL.LOCATION') }}</span>
                    </th>
                    <th v-if="tableView === 'advanced'">
                      <span>Address</span>
                    </th>
                    <th v-if="tableView === 'advanced'"><span>City</span></th>
                    <th><span>Imei</span></th>
                    <th><span>Payment</span></th>
                    <th>
                      <span>{{ $t('GENERAL.AMOUNT') }}</span>
                    </th>
                    <th class="text-right pr-6"><span>Date & Time</span></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(transaction, key) in transactions" :key="key">
                    <td class="pl-6" :class="{ 'border-top-0': key === 0 }">
                      <span
                        class="text-dark-75 font-weight-bolder mb-1 font-size-lg"
                        >{{
                          transaction.deviceMeta
                            ? transaction.deviceMeta.name
                            : ''
                        }}</span
                      >
                      <div>
                        <span
                          v-for="(tag, i) in transaction.locationMeta.tags"
                          :key="i"
                          class="label label-sm label-light-primary label-inline mr-2"
                          >{{ tag }}</span
                        >
                      </div>
                    </td>
                    <td
                      v-if="tableView === 'advanced'"
                      :class="{ 'border-top-0': key === 0 }"
                    >
                      <span>{{ transaction.deviceMeta.isuNumber }}</span>
                    </td>
                    <td
                      v-if="tableView === 'advanced'"
                      :class="{ 'border-top-0': key === 0 }"
                    >
                      <span>{{
                        transaction.deviceMeta.businessSpaceLabel
                      }}</span>
                    </td>
                    <td :class="{ 'border-top-0': key === 0 }">
                      <span>{{ transaction.locationMeta.title }}</span>
                    </td>
                    <td
                      v-if="tableView === 'advanced'"
                      :class="{ 'border-top-0': key === 0 }"
                    >
                      <span>{{ transaction.locationMeta.address }}</span>
                    </td>
                    <td
                      v-if="tableView === 'advanced'"
                      :class="{ 'border-top-0': key === 0 }"
                    >
                      <span>{{ transaction.locationMeta.city }}</span>
                    </td>
                    <td :class="{ 'border-top-0': key === 0 }">
                      <span>{{ transaction.imei }}</span>
                    </td>
                    <td :class="{ 'border-top-0': key === 0 }">
                      <span
                        v-if="
                          transaction.paymentType === 'pay_coin' ||
                          transaction.paymentType === 'pay_cctalk' ||
                          transaction.paymentType === 'pay_mdb'
                        "
                        class="label label-lg label-inline label-light-primary"
                        >Coin</span
                      >
                      <span
                        v-if="transaction.paymentType === 'pay_sms'"
                        class="label label-lg label-inline label-light-primary"
                        >SMS</span
                      >
                      <span
                        v-if="transaction.paymentType === 'token_drop'"
                        class="label label-lg label-inline label-light-primary"
                        >Token Drop</span
                      >
                      <span
                        v-if="transaction.paymentType === 'pay_token'"
                        class="label label-lg label-inline label-light-primary"
                        >Token</span
                      >
                    </td>
                    <td :class="{ 'border-top-0': key === 0 }">
                      <span class="font-weight-bold" v-if="transaction.paymentType === 'pay_token'">{{
                        parseFloat(transaction.amount)
                      }}</span>
                      <span class="font-weight-bold" v-else>{{
                        parseFloat(transaction.amount) | currencyFormat
                      }}</span>
                    </td>
                    <td
                      class="text-right pr-6"
                      :class="{ 'border-top-0': key === 0 }"
                    >
                      <span>{{ transaction.createdAt | formatDateTime }}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div
                class="mt-10 overflow-auto d-flex justify-content-center align-items-center"
              >
                <b-pagination-nav
                  :link-gen="linkGen"
                  :number-of-pages="pagination.totalPages"
                  use-router
                  size="lg"
                ></b-pagination-nav>
              </div>
            </div>
            <div v-else-if="!isLoading && transactions.length === 0" class="row" style="display: flex; align-items: center; justify-content: center; flex-direction: column; height: 100%;">
              <div class="col-12 pt-4 pb-6 text-center px-6 pl-10">
                <div style="display: flex; align-items: center; justify-content: center; flex-direction: column; height: 100%;">
                  <p class="font-weight-bold" style="font-size: 24px;">No transactions.</p>
                  <p style="font-size: 16px;">There are currently no transactions.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <b-modal id="location_modal" centered hide-header hide-footer>
          <div v-if="modalData">
            <b-button
              class="btn btn-light-primary mb-3"
              block
              @click="$bvModal.hide('location_modal')"
              >Close</b-button
            >
            <div>
              <span class="font-weight-bold">{{ $t('GENERAL.AMOUNT') }}: </span
              ><span>{{ modalData.amount }}</span>
            </div>
            <div>
              <span class="font-weight-bold">{{ $t('GENERAL.DEVICE') }}: </span
              ><span>{{ modalData.device.name }}</span>
            </div>
            <div>
              <span class="font-weight-bold">IMEI: </span
              ><span>{{ modalData.device.logger.imei }}</span>
            </div>
            <div>
              <span class="font-weight-bold">Business Space Label: </span
              ><span>{{ modalData.device.businessSpaceLabel }}</span>
            </div>
            <div>
              <span class="font-weight-bold">ISU Number: </span
              ><span>{{ modalData.device.isuNumber }}</span>
            </div>
            <div>
              <span class="font-weight-bold"
                >{{ $t('GENERAL.LOCATION') }}: </span
              ><span>{{ modalData.device.location.title }}</span>
            </div>
            <div>
              <span class="font-weight-bold">Saldo: </span
              ><span>{{ modalData.device.saldo }}</span>
            </div>
          </div>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import ApiService from '@/core/services/api.service'
import format from 'date-fns/format'
import { mapGetters } from 'vuex'

export default {
  name: 'TransactionsList',
  components: {
    DatePicker,
  },
  data() {
    return {
      transactions: [],
      pagination: {
        totalPages: 0,
        currentPage: 1,
        itemsPerPage: 30,
      },
      totalItems: null,
      isLoading: true,
      range: {
        start: null,
        end: null,
      },
      masks: {
        input: 'dd.MM.yyyy - HH:mm:ss',
      },
      lastTransaction: null,
      fromText: '',
      toText: '',
      modalData: null,
      imei: null,
      tableView: 'simple',
      tableViewButtonText: 'Advanced View',
    }
  },
  computed: {
    ...mapGetters(['currentUserRole', 'currentUserCompanyOib']),
    page() {
      return this.$route.query.page ?? 1
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Transactions', route: '/transactions/list' },
    ])

    this.getTransactions()
  },
  watch: {
    page() {
      this.rangeSelected()
    },
  },
  methods: {
    linkGen(pageNum) {
      return pageNum === 1 ? '?' : `?page=${pageNum}`
    },
    filter() {
      this.$router.replace({ query: {} })
      this.rangeSelected()
    },
    rangeSelected() {
      this.isLoading = true
      this.transactions = []

      this.pagination.currentPage = this.$route.query.page || 1

      if (this.range && this.range.start && this.range.end) {
        const after = format(this.range.start, "yyyy-MM-dd'T'HH:mm:ss")
        const before = format(this.range.end, "yyyy-MM-dd'T'HH:mm:ss")

        if (
          this.currentUserRole === 'ROLE_WATCHER_BILLS'
        ) {
          let query = null

          if (this.imei)
            query = `?imei=${this.imei}&createdAt[after]=${after}&createdAt[before]=${before}&order[createdAt]=desc&itemsPerPage=${this.pagination.itemsPerPage}&page=${this.pagination.currentPage}&isWatcherBills=true`
          else
            query = `?createdAt[after]=${after}&createdAt[before]=${before}&order[createdAt]=desc&itemsPerPage=${this.pagination.itemsPerPage}&page=${this.pagination.currentPage}&isWatcherBills=true`

          ApiService.get(`transactions-new`, query)
            .then(({ data }) => {
              const filteredTransactions = data.data.filter((item) => item.paymentType !== 'token_drop')
              this.transactions = filteredTransactions
              this.totalItems = filteredTransactions.length
              this.pagination.totalPages = Math.ceil(
                data.totalItems / this.pagination.itemsPerPage
              )
            })
            .finally(() => {
              this.isLoading = false
            })
        }

        if (
          this.currentUserRole === 'ROLE_ROOT' ||
          this.currentUserRole === 'ROLE_SUPER_ADMIN'
        ) {
          let query = null

          if (this.imei)
            query = `?imei=${this.imei}&createdAt[after]=${after}&createdAt[before]=${before}&order[createdAt]=desc&itemsPerPage=${this.pagination.itemsPerPage}&page=${this.pagination.currentPage}`
          else
            query = `?createdAt[after]=${after}&createdAt[before]=${before}&order[createdAt]=desc&itemsPerPage=${this.pagination.itemsPerPage}&page=${this.pagination.currentPage}`

          ApiService.get(`transactions-new`, query)
            .then(({ data }) => {
              const filteredTransactions = data.data.filter((item) => item.paymentType !== 'token_drop')
              this.transactions = filteredTransactions
              this.totalItems = filteredTransactions.length
              this.pagination.totalPages = Math.ceil(
                data.totalItems / this.pagination.itemsPerPage
              )
            })
            .finally(() => {
              this.isLoading = false
            })
        }

        if (
          this.currentUserRole === 'ROLE_ADMIN' ||
          this.currentUserRole === 'ROLE_WORKER' ||
          this.currentUserRole === 'ROLE_JUNIOR'
        ) {
          let query = null
          if (this.imei)
            query = `?imei=${this.imei}&createdAt[after]=${after}&createdAt[before]=${before}&order[createdAt]=desc&itemsPerPage=${this.pagination.itemsPerPage}&page=${this.pagination.currentPage}`
          else
            query = `?oib=${this.currentUserCompanyOib}&createdAt[after]=${after}&createdAt[before]=${before}&order[createdAt]=desc&itemsPerPage=${this.pagination.itemsPerPage}&page=${this.pagination.currentPage}`

          ApiService.get(`transactions-new`, query)
            .then(({ data }) => {
              const filteredTransactions = data.data.filter((item) => item.paymentType === 'token_drop')
              this.transactions = filteredTransactions
              this.totalItems = filteredTransactions.length
              this.pagination.totalPages = Math.ceil(
                data.totalItems / this.pagination.itemsPerPage
              )
            })
            .finally(() => {
              this.isLoading = false
            })
        }
      } else {
        if (
          this.currentUserRole === 'ROLE_WATCHER_BILLS'
        ) {
          let query = null

          if (this.imei)
            query = `?imei=${this.imei}&order[createdAt]=desc&itemsPerPage=${this.pagination.itemsPerPage}&page=${this.pagination.currentPage}&isWatcherBills=true`
          else
            query = `?order[createdAt]=desc&itemsPerPage=${this.pagination.itemsPerPage}&page=${this.pagination.currentPage}&isWatcherBills=true`

          ApiService.get(`transactions-new`, query)
            .then(({ data }) => {
              const filteredTransactions = data.data.filter((item) => item.paymentType !== 'token_drop')
              this.transactions = filteredTransactions
              this.totalItems = filteredTransactions.length
              this.pagination.totalPages = Math.ceil(
                data.totalItems / this.pagination.itemsPerPage
              )
            })
            .finally(() => {
              this.isLoading = false
            })
        }

        if (
          this.currentUserRole === 'ROLE_ROOT' ||
          this.currentUserRole === 'ROLE_SUPER_ADMIN'
        ) {
          let query = null

          if (this.imei)
            query = `?imei=${this.imei}&order[createdAt]=desc&itemsPerPage=${this.pagination.itemsPerPage}&page=${this.pagination.currentPage}`
          else
            query = `?order[createdAt]=desc&itemsPerPage=${this.pagination.itemsPerPage}&page=${this.pagination.currentPage}`

          ApiService.get(`transactions-new`, query)
            .then(({ data }) => {
              const filteredTransactions = data.data.filter((item) => item.paymentType !== 'token_drop')
              this.transactions = filteredTransactions
              this.totalItems = filteredTransactions.length
              this.pagination.totalPages = Math.ceil(
                data.totalItems / this.pagination.itemsPerPage
              )
            })
            .finally(() => {
              this.isLoading = false
            })
        }

        if (
          this.currentUserRole === 'ROLE_ADMIN' ||
          this.currentUserRole === 'ROLE_WORKER' ||
          this.currentUserRole === 'ROLE_JUNIOR'
        ) {
          let query = null

          if (this.imei)
            query = `?imei=${this.imei}&order[createdAt]=desc&itemsPerPage=${this.pagination.itemsPerPage}&page=${this.pagination.currentPage}`
          else
            query = `?oib=${this.currentUserCompanyOib}&order[createdAt]=desc&itemsPerPage=${this.pagination.itemsPerPage}&page=${this.pagination.currentPage}`

          ApiService.get(`transactions-new`, query)
            .then(({ data }) => {
              const filteredTransactions = data.data.filter((item) => item.paymentType !== 'token_drop')
              this.transactions = filteredTransactions
              this.totalItems = filteredTransactions.length
              this.pagination.totalPages = Math.ceil(
                data.totalItems / this.pagination.itemsPerPage
              )
            })
            .finally(() => {
              this.isLoading = false
            })
        }
      }
    },
    viewLocation(endpoint, transaction) {
      this.modalData = {
        amount: transaction.amount,
        paymentType: transaction.paymentType,
        imei: transaction.imei,
        device: null,
        location: null,
      }

      this.getEntity(transaction.device.replace('/api/v1/', '')).then(
        ({ data }) => {
          this.modalData.device = data
          this.$root.$emit('bv::show::modal', 'location_modal', '#btnShow')
        }
      )
    },
    async getEntity(endpoint) {
      try {
        return await ApiService.get(endpoint)
      } catch (e) {
        throw new Error(e)
      }
    },
    refresh() {
      this.transactions = []
      this.totalItems = null

      this.getTransactions()
    },
    exportAsExcel() {
      const from = format(this.range.start, "yyyy-MM-dd'T'HH:mm:ss")
      const end = format(this.range.end, "yyyy-MM-dd'T'HH:mm:ss")
      let query = null
      if (this.imei)
        query = `${process.env.VUE_APP_ROUTE_API_URL}/api/v1/exports/xlsx/company-imei-transactions/${this.currentUserCompanyOib}/${this.imei}/${from}/${end}`
      else
        query = `${process.env.VUE_APP_ROUTE_API_URL}/api/v1/exports/xlsx/company-transactions/${this.currentUserCompanyOib}/${from}/${end}`

      window.open(query, '_blank').focus()
    },
    changeTableView(currentView) {
      if (currentView === 'simple') {
        this.tableView = 'advanced'
        this.tableViewButtonText = 'Simple View'
      } else {
        this.tableView = 'simple'
        this.tableViewButtonText = 'Advanced View'
      }
    },
    getTransactions() {
      this.pagination.currentPage = this.$route.query.page || 1

      this.isLoading = true

      if (
          this.currentUserRole === 'ROLE_WATCHER_BILLS'
        ) {
          ApiService.get(
          `transactions-new`,
          `?order[createdAt]=desc&itemsPerPage=${this.pagination.itemsPerPage}&page=${this.pagination.currentPage}&isWatcherBills=true`
        )
          .then(({ data }) => {
            this.totalItems = data.data.length
            ;(this.transactions = data.data),
              (this.lastTransaction =
                this.transactions[this.transactions.length - 1])
            this.fromText = this.lastTransaction.createdAt
            this.toText = 'now'
            this.pagination.totalPages = Math.ceil(
              data.totalItems / this.pagination.itemsPerPage
            )
          })
          .finally(() => {
            this.isLoading = false
          })
        }

      if (
        this.currentUserRole === 'ROLE_ROOT' ||
        this.currentUserRole === 'ROLE_SUPER_ADMIN'
      ) {
        ApiService.get(
          `transactions-new`,
          `?order[createdAt]=desc&itemsPerPage=${this.pagination.itemsPerPage}&page=${this.pagination.currentPage}`
        )
          .then(({ data }) => {
            this.totalItems = data.data.length
            ;(this.transactions = data.data),
              (this.lastTransaction =
                this.transactions[this.transactions.length - 1])
            this.fromText = this.lastTransaction.createdAt
            this.toText = 'now'
            this.pagination.totalPages = Math.ceil(
              data.totalItems / this.pagination.itemsPerPage
            )
          })
          .finally(() => {
            this.isLoading = false
          })
      }

      if (
        this.currentUserRole === 'ROLE_ADMIN' ||
        this.currentUserRole === 'ROLE_WORKER' ||
        this.currentUserRole === 'ROLE_JUNIOR'
      ) {
        ApiService.get(
          `transactions-new`,
          `?oib=${this.currentUserCompanyOib}&order[createdAt]=desc&itemsPerPage=${this.pagination.itemsPerPage}&page=${this.pagination.currentPage}`
        )
          .then(({ data }) => {
            this.totalItems = data.data.length
            ;(this.transactions = data.data),
              (this.lastTransaction =
                this.transactions[this.transactions.length - 1])
            this.fromText = this.lastTransaction.createdAt
            this.toText = 'now'
            this.pagination.totalPages = Math.ceil(
              data.totalItems / this.pagination.itemsPerPage
            )
          })
          .finally(() => {
            this.isLoading = false
          })
      }
    },
  },
}
</script>

<style>
.vc-container {
  font-family: 'Poppins', sans-serif !important;
}
</style>
